<template>
    <div class="row">
        <template v-if="dispute">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pb-3">
                <div class='card shadow-lg'>
                    <div class='card-header d-print-none'>
                        <h3>{{ dispute.dispute_type }} Dispute</h3>
                        <div class="d-flex">

                            <button v-if="dispute.active" class="btn btn-danger me-2"
                                @click="showModal('Close Dispute', 'Are you sure you want to close the dispute?', 'Yes', 'No', () => DisableDispute(dispute.id))">Close
                                dispute</button>
                            <button v-else class="btn btn-success me-2"
                                @click="showModal('Re-Open Dispute', 'Are you sure you want to re-open the Dispute?', 'Yes', 'No', () => EnableDispute(dispute.id))">Re-Open
                                dispute</button>

                            <button class="btn btn-success ms-auto me-2" @click="downloadPDF()">Download PDF</button>
                            <button class="btn btn-info " @click="generatePDF()">Re-Send Email PDF</button>
                        </div>
                    </div>
                    <div class='card-body'>

                        <div class='row'>
                            <div class='col-12'>
                                <h4><strong>Reference: </strong>{{ $filters.reference(dispute.id) }}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <h4><strong>Date Of Lodgement: </strong>{{ $filters.date(dispute.date_created) }}</h4>
                            </div>
                            <div class="col-6">
                                <h4><strong>Date Of Initial Complaint: </strong>{{
                                    $filters.date(dispute.initial_date_of_dispute) }}</h4>
                            </div>
                        </div>
                        <hr>

                        <div class="d-none d-print-block">
                            <Legislation height="auto"/>
                            <hr>
                        </div>

                        <h4 style="font-weight: bold; page-break-before: always;">Declaration</h4>
                        <p class="d-none d-print-block">
                            NOTE: The Dispute form will remain hidden until you confirm that
                            you have ready and understood the legislation above.
                            By selecting Yes you acknowledge that you have read
                            and understood the legislation.
                        </p>
                        <h5>Legislation Read and Understood?
                            <strong>Yes</strong> / <s>No</s>
                        </h5>
                        <hr>

                        <table id="personal info" class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">ELM Account Holder Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Full Name</th>
                                    <td>{{ dispute.account.first_name }} {{ dispute.account.last_name }}</td>
                                </tr>
                                <tr>
                                    <th>Phone Number</th>
                                    <td>
                                        <span v-if="dispute.account.primary_contact">{{ dispute.account.primary_contact
                                            }}</span>
                                        <span v-if="dispute.account.alternative_contact"><br>{{
                                            dispute.account.alternative_contact }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{{ dispute.account.email }}</td>
                                </tr>
                                <tr>
                                    <th>ELM Account Number</th>
                                    <td>{{ dispute.account.municipal_account_number }}</td>
                                </tr>
                                <tr v-if="dispute.statement">
                                    <th>ELM Statement</th>
                                    <td>
                                        <a style="text-decoration: underline;" class="link-primary"
                                            :href="dispute.statement" download="statement.pdf">
                                            View Statement
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="dispute.proof_of_payment">
                                    <th>ELM Proof of Payment</th>
                                    <td>
                                        <a style="text-decoration: underline;" class="link-primary"
                                            :href="dispute.proof_of_payment" download="proof_of_payment.pdf">
                                            View Proof of Payment
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Physical Address</th>
                                    <td>
                                        {{ dispute.account.street_number }}<br>
                                        {{ dispute.account.street_name }}<br>
                                        {{ dispute.account.suburb.suburb }}<br>
                                        {{ dispute.account.town.town }}<br>
                                        {{ dispute.account.suburb.postcode }}<br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr>

                        <table id="dispute criteria" class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">Dispute Criteria</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Selected the reason/s for lodging this Billing Dispute</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Amount Disputed</th>
                                    <td>{{ $filters.price(dispute.amount_disputed) }}</td>
                                </tr>
                                <tr>
                                    <th>Comment</th>
                                    <td>
                                        <p style="white-space: pre;">{{ dispute.reason }}</p>
                                    </td>
                                </tr>
                            </tbody>

                        </table>


                        <div id="embeds" class="d-print-none">
                            <hr>
                            <h5>View Statement</h5>
                            <embed :src="dispute.statement" style="height: 400px; width: 100%; padding-bottom: 20px;" />
                            <h5>View Proof of Payment</h5>
                            <embed :src="dispute.proof_of_payment" style="height: 400px; width: 100%; " />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-print-none">
                <div class='card'>
                    <div class='card-header'>
                        <h3>Comments</h3>
                    </div>
                    <div class='card-body'>
                        <template v-for="comment in dispute.comments">
                            <div id="comment">
                                <div style="background-color: #f6f6f6;">
                                    <hr>
                                    <p>
                                        Posted By <span>{{ comment.account }}</span>
                                        on {{ $filters.dateTime(comment.date_created) }}
                                    </p>
                                    <hr>
                                </div>
                                <div>
                                    <p style="white-space: pre;">{{ comment.comment }}</p>
                                </div>
                                <hr>
                            </div>
                        </template>
                    </div>
                    <div class='card-footer'>
                        <form @submit.prevent="CreateComment">
                            <label class="mb-2" for="comment">Reply to dispute</label>
                            <textarea v-model="comment" type="text" class="form-control" aria-describedby="descHelp"
                                rows="8" maxlength="1000"></textarea>
                            <br>
                            <button class="btn btn-primary w-100">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            Dispute Not Found
        </template>
    </div>

    <template v-if="dispute && print">
        <div id="my_dispute" class="m-2 p-2 pe-4">
            <div class='card'>
                <div class='card-header d-print-none'>
                    <h3>{{ dispute.dispute_type }} Dispute</h3>
                </div>
                <div class='card-body'>

                    <div class='row'>
                        <div class='col-12'>
                            <h4><strong>Reference: </strong>{{ $filters.reference(dispute.id) }}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <h4><strong>Date Of Lodgement: </strong>{{ $filters.date(dispute.date_created) }}</h4>
                        </div>
                        <div class="col-6">
                            <h4><strong>Date Of Initial Complaint: </strong>{{
                                $filters.date(dispute.initial_date_of_dispute) }}</h4>
                        </div>
                    </div>
                    <hr>

                    <div>
                        <Legislation height="auto" />
                        <hr>
                    </div>

                    <h4 class="pt-3" style="font-weight: bold; page-break-before: always;">Declaration</h4>
                    <p>
                        NOTE: The Dispute form will remain hidden until you confirm that 
                        you have ready and understood the legislation above. 
                        By selecting Yes you acknowledge that you have read 
                        and understood the legislation.
                    </p>
                    <h5>Legislation Read and Understood?
                        <strong>Yes</strong> / <s>No</s>
                    </h5>
                    <hr>

                    <h4 class="pt-3" style="font-weight: bold;">ELM Account Holder Information</h4>

                    <table id="personal info" class="table">
                        <tbody>
                            <tr>
                                <th>Full Name</th>
                                <td>{{ dispute.account.first_name }} {{ dispute.account.last_name }}</td>
                            </tr>
                            <tr>
                                <th>Phone Number</th>
                                <td>
                                    <span v-if="dispute.account.primary_contact">{{ dispute.account.primary_contact
                                        }}</span>
                                    <span v-if="dispute.account.alternative_contact"><br>{{
                                        dispute.account.alternative_contact }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{ dispute.account.email }}</td>
                            </tr>
                            <tr>
                                <th>ELM Account Number</th>
                                <td>{{ dispute.account.municipal_account_number }}</td>
                            </tr>
                            <tr>
                                <th>Physical Address</th>
                                <td>
                                    {{ dispute.account.street_number }}<br>
                                    {{ dispute.account.street_name }}<br>
                                    {{ dispute.account.suburb.suburb }}<br>
                                    {{ dispute.account.town.town }}<br>
                                    {{ dispute.account.suburb.postcode }}<br>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <hr>

                    <h4 class="pt-3" style="font-weight: bold; page-break-before: always;">Dispute Criteria</h4>

                    <table id="dispute criteria" class="table">
                        <tbody>
                            <tr>
                                <th colspan="2">Selected the reason/s for lodging this Billing Dispute</th>
                            </tr>
                            <template v-if="dispute.criteria.length > 0">
                                <template v-for="crit, idx in dispute.criteria">
                                    <tr>
                                        <th colspan="2">
                                            {{ crit.header }} - I declare...
                                            <template v-if="crit.body">
                                                <div :id="'crit_' + idx">
                                                    {{ readOnlyQuill('crit_' + idx, crit.body) }}
                                                </div> <!-- quill -->
                                            </template>
                                        </th>
                                    </tr>
                                </template>
                            </template>
                            <tr>
                                <th>Amount Disputed</th>
                                <td>{{ $filters.price(dispute.amount_disputed) }}</td>
                            </tr>
                            <tr>
                                <th>Comment</th>
                                <td>
                                    <p style="white-space: pre;">{{ dispute.reason }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <hr>

                    <div id="turnAroundTimes" class='card'>
                        <div class='card-header'>
                            <h3>Resolution Turnaround</h3>
                        </div>
                        <div class='card-body'>
                            <p>
                                In terms of sections 22.5 and 22.6 of the Bylaw, the ELM must investigate the dispute
                                within 14 business
                                days,
                                and communicate the resolution to you, within 21 business days of this submission.
                                Please act in accordance
                                with
                                this legislation
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped>
.link {
    text-decoration: underline;
    color: #0d6efd;
    cursor: pointer;
}

h5 {
    font-size: medium;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { showModal } from '../../components/customModal.js'
import Legislation from './../Core/Legislation.vue'
import { useToast } from 'vue-toastification'
import { readOnlyQuill } from '@/components/quillManager'

const html2pdf = require('html2pdf.js')

const toast = useToast();
let store = useStore();
let route = useRoute();

let id = route.params.id || undefined;

let dispute = ref(undefined);
let comment = ref(undefined);
let pdf = ref(undefined)
let print = ref(false)

onMounted(async () => {
    await getDispute();
    if (!dispute.value.dispute) {
        await generatePDF()
        toast.success('Successfully created dispute', {
            timeout: 3000
        });
    }
})

async function getDispute() {
    const response = await store.dispatch(
        'get',
        store.state.account && store.state.account.is_staff ? `disputes/detail/${id}/` : `disputes/detail/public/${id}/`
    );
    console.log(response.data);
    if (response.status === 200) {
        dispute.value = response.data;
    }
}

async function CreateComment() {
    if (!comment.value) {
        toast.error('Please fill in a comment before continuing', {
            timeout: 3000
        });
    }

    const payload = {
        api: `disputes/comment/${id}/`,
        data: {
            comment: comment.value
        }
    }

    const response = await store.dispatch('post', payload);

    if (response.status == 201) {
        comment.value = '';
        window.scrollTo(0, 0);
        getDispute();
    }
}

async function DisableDispute(id) {
    let reason = prompt("Please provide reason for closing the dispute", "")
    if (reason == null || reason == "") { return }
    if (id) {
        const payload = {
            api: `disputes/disable/${route.params.id}/`,
            data: { 'comment': reason }
        };

        const response = await store.dispatch('post', payload);

        if (response.status === 200) {
            await getDispute();
        }
    }
}

async function EnableDispute(id) {
    let reason = prompt("Please provide reason for re-opening dispute", "")
    if (reason == null || reason == "") { return }
    console.log(reason);
    if (id) {
        const payload = {
            api: `disputes/enable/${route.params.id}/`,
            data: { 'comment': reason }
        };

        const response = await store.dispatch('post', payload);

        if (response.status === 200) {
            await getDispute();
        }
    }
}

async function generatePDF() {
    let checkQueue = await store.dispatch('get', `disputes/pdf/${id}/`)
    if (checkQueue.status == 400) {
        toast.error(checkQueue.data.email, {
            timeout: 5000
        });
        return
    }
    store.commit('setIsLoading', true)
    print.value = true
    await delay(100);

    toast.info('Generating PDF for Email', {
        timeout: 5000
    });

    let element = document.getElementById('my_dispute');
    var opt = {
        padding: 0,
        margin: 0,
        filename: 'dispute.pdf',
        image: { type: 'jpeg', quality: 0.6 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    await html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .output('datauristring')
        .then(async (pdfBase64) => {
            //TODO send file to backend
            // const file = new File(
            //     [pdfBase64],
            //     'dispute.pdf',
            //     {type: 'application/pdf'}
            // )
            const formData = new FormData()
            formData.append("dispute", pdfBase64)
            let payload = {
                api: `disputes/pdf/${id}/`,
                data: formData
            }
            const response = await store.dispatch('put', payload)
            if (response.status == 400) {
                toast.error(response.data.email, {
                    timeout: 5000
                });
                return
            }
        })
    print.value = false
    store.commit('setIsLoading', false)
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

async function downloadPDF() {
    store.commit('setIsLoading', true)
    toast.info('Generating PDF for Download', {
        timeout: 5000
    });
    print.value = true
    await delay(100);
    store.commit('setIsLoading', false)

    let element = document.getElementById('my_dispute');
    if (print.value) {
        var opt = {
            padding: 0,
            margin: 0,
            filename: 'dispute.pdf',
            image: { type: 'jpeg', quality: 0.6 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        pdf.value = html2pdf()
            .set(opt)
            .from(element)
            .save();
    }

    print.value = false
}

</script>